import React from "react";
import Img1 from "../assets/images/wood-logs-texture-min.jpg";
import Img2 from "../assets/images/austin-wehrwein-r5T3Z_BOmDE-unsplash-min.jpg";
import Img3 from "../assets/images/tree-1033614_1280-min.jpg";
import Img4 from "../assets/images/img4.jpeg"


function Presta() {
    return (
        <>
<div className="services" id="services">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="section-heading">
          <h2>Nos <em>Prestations</em></h2>
          <span>Vous êtes à la recherche de bois de qualité pour vous chauffer cet hiver ?</span>
        </div>
      </div>
      <div className="col-md-6">
        <div className="service-item">
          <img src={Img1} alt ="img1" />
          <div className="down-content">
            <h4>Vente de stères de bois</h4>
            <p>Différentes longueurs de bois en stère sont disponibles pour répondre à tous vos besoins en matière de chauffage. Que ce soit pour des bûches mesurant 33cm, 50cm ou même 1m.</p>
           

            {/* <a href className="filled-button">Nos Tarifs</a> */}
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="service-item">
          <img src={Img2} alt="img2" />
          <div className="down-content">
            
            <h4>Service de livraison</h4>
            <p>Vous souhaitez vous fournir en bois ? La livraison à domicile est assurée dans un rayon de 10 km autour de SAALES, dans les plus brefs délais et dans les quantités que vous désirez.</p>
            {/* <a href className="filled-button">Nos Tarifs</a> */}
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="service-item">
          <img src={Img3}alt="img3"/>
          <div className="down-content">
            <h4>Nos essences de bois</h4>
            <p>Nous proposons à la vente une large gamme de bois de qualité, comprenant du hêtre, du chêne, un mélange de différentes essences ainsi que du bois résineux celui-ci sur demande</p>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="service-item">
          <img src={Img4}alt="img4"/>
          <div className="down-content">
            <h4>Abattage d'arbre</h4>
            <p>Offrez à votre jardin le soin qu'il mérite en optant pour notre service d'abattage d'arbres, réalisé avec expertise et souci de votre environnement. Uniquement sur devis.</p>
          </div>
        </div>
      </div>
      

            {/* <a href className="filled-button">Nos Tarifs</a> */}
    </div>
      
  </div>
</div>

        </>
    )
}

export default Presta