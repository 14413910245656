import React from "react";
import logokreativit from "../assets/images/logoK.png";
import logokiwaise from "../assets/images/kiwaise.png";
import { Link } from "react-router-dom";

function Partner() {
  return (
    <div>
      <div className="partners">
        <div className="mypartners">
        <h2>Nos <em>Partenaires</em></h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="owl-partners owl-carousel">
                <div className="partner-item">
                  <div className="logokreativit">

                  <Link to="https://kreativ-it.fr">
                    <img  src={logokreativit} alt="kreativit" />
                  </Link>
                  </div>
                </div>
                <div className="partner-item">
                  <div className="logokiwaise">

                  <Link to="https://www.facebook.com/kiwaise67/?locale=fr_FR">
                    <img  src={logokiwaise} alt="kiwaise" />
                  </Link>
                  </div>
                </div>
                {/* <div className="partner-item">
              <img src="assets/images/client-01.png" title={3} alt={3} />
            </div>
            <div className="partner-item">
              <img src="assets/images/client-01.png" title={4} alt={4} />
            </div>
            <div className="partner-item">
              <img src="assets/images/client-01.png" title={5} alt={5} />
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Partner;
