import React from "react";
import SubHeader from "./components/SubHeader";


// import Banner from "./Banner";
import Presta from "./components/Presta";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Partner from "./components/Partner";
import Slider from "./components/Slider";
import SideBar from "./components/SideBar";



function App() {
  return (
    <div className="App">
    
        <SubHeader />
    <SideBar/>
        <Slider/>
        {/* <Banner/> */}
        <Presta/>
        <Contact/>
        <Partner/>
        <Footer/>
      
    </div>
  );
}

export default App;
