import React from "react";
import { Link } from "react-router-dom";



function SubHeader() {
    return (
        <>
<div className="sub-header">
  <div className="container">
    <div className="row">
      <div className="col-md-8 col-xs-12">
        <ul className="left-info">
        <li><a href="mailto:forestgabs@gmail.com"><i class="fa fa-envelope"></i>forestgabs@gmail.com</a></li>
          {/* <li><Link to=""><i className="fa fa-envelope" />forestgabs@gmail.com</Link></li> */}
          <li><a href="tel://0622515280"><i className="fa fa-phone"></i> 06-12-89-47-26</a></li>
        </ul>
      </div>
      <div className="col-md-4">
        <ul className="right-icons">
          <li><Link to="#"><i className="fa fa-facebook" /></Link></li>
          {/* <li><Link to="#"><i className="fa fa-twitter" /></Link></li>
          <li><Link to="#"><i className="fa fa-linkedin" /></Link></li>
          <li><Link to="#"><i className="fa fa-behance" /></Link></li> */}
        </ul>
      </div>
    </div>
  </div>
</div>

        </>
    )
}

export default SubHeader