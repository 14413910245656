import React from "react";
import { FaCcVisa } from "react-icons/fa6";
import { RiMoneyEuroCircleLine } from "react-icons/ri";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import Logo from "../assets/images/Logo PNG 2.png"

function Footer () {
    return(
        <>
<footer>
  <div className="container">
    <div className="row">
      <div className="col-md-3 footer-item">
        <h4>Nos moyens de paiement</h4> 
       

        {/* <p>Vivamus tellus mi. Nulla ne cursus elit,vulputate. Sed ne cursus augue hasellus lacinia sapien vitae.</p> */}
        <ul className="paiement-icons">
          <li><FaCcVisa /><p>Carte bancaire</p></li>
          <li><RiMoneyEuroCircleLine /><p>Espèces</p></li>
          <li><FaMoneyBillTransfer /> <p>Virement bancaire</p></li>
          
          
        </ul>
      </div>
      {/* <div className="col-md-3 footer-item">
        <h4>Useful Links</h4>
        <ul className="menu-list">
          <li><a href="#">Vivamus ut tellus mi</a></li>
          <li><a href="#">Nulla nec cursus elit</a></li>
          <li><a href="#">Vulputate sed nec</a></li>
          <li><a href="#">Cursus augue hasellus</a></li>
          <li><a href="#">Lacinia ac sapien</a></li>
        </ul>
      </div> */}
      <div class="container-center">
      <img src={Logo} alt="logo" />
          {/* <a class="text" href="index.html"><h2>Forest<em>Gab's</em></h2></a> */}
          </div>
      <div className="col-md-3 footer-item">
        <h4>Nous Contacter</h4>
        <ul className="contact-list">
        <li><a href="tel://0612894726"><i className="fa fa-phone"></i><p>06-12-89-47-26 <br/>06-22-51-52-80
          </p></a></li>

        <li><a href="mailto:forestgabs@gmail.com"><i class="fa fa-envelope"></i><p>forestgabs@gmail.com
          </p></a></li>
        <li><a href="https://www.facebook.com/profile.php?id=61560986795298"><i class="fa fa-facebook"></i><p>Forest Gab's
          </p></a></li>
        
          {/* <li><a href="#">Contact Us</a></li>
          <li><a href="#">Privacy Policy</a></li> */}
        </ul>
      </div>
      {/* <div className="col-md-3 footer-item last-item">
        <h4>Contact Us</h4>
        <div className="contact-form">
          <form id="contact footer-contact" action method="post">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <fieldset>
                  <input name="name" type="text" className="form-control" id="name" placeholder="Full Name" required />
                </fieldset>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <fieldset>
                  <input name="email" type="text" className="form-control" id="email" pattern="[^ @]*@[^ @]*" placeholder="E-Mail Address" required />
                </fieldset>
              </div>
              <div className="col-lg-12">
                <fieldset>
                  <textarea name="message" rows={6} className="form-control" id="message" placeholder="Your Message" required defaultValue={""} />
                </fieldset>
              </div>
              <div className="col-lg-12">
                <fieldset>
                  <button type="submit" id="form-submit" className="filled-button">Send Message</button>
                </fieldset>
              </div>
            </div>
          </form>
        </div>
      </div> */}
    </div>
  </div>
</footer>
<div className="sub-footer">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <p>Copyright © 2024 Forest Gab's
          - Réalisation : <a rel="nofollow noopener" href="https://kreativ-it.fr" target="_blank">Kreativ'IT</a><br />
          
        </p>
      </div>
    </div>
  </div>
</div>

        </>
    )
}

export default Footer