import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import "./Slider.css";
import Img1 from "../assets/images/bois5-min.jpg";
import Img2 from "../assets/images/pexels-hannahmgibbs-949273-min.jpg";
import Img3 from "../assets/images/ax-tree-trunk-background-roadway-min.jpg";

// function Slider() {
//   return (
//     <Carousel autoPlay interval={6000} infiniteLoop  >
//       <div className="card">
//         <img src={Img1}/>
//       </div>
//       <div className="card">
//         <img src={Img2} />
//       </div>
//       <div className="card">
//         <img src={Img3} />
//       </div>
//     </Carousel>
//   );
// }

// export default Slider

function Slider() {
  return (
    <>
      <div className="full-width-carousel">
       <Carousel autoPlay infiniteLoop showThumbs={false} >
          <div className="full-width-slide">
            {/* <h4 className="text">Ventes de bois de chauffage</h4> */}
            <img src={Img1} alt="Slide 1" className="slide-image" />
          </div>
          <div className="full-width-slide">
          {/* <h4 className="text">Ventes de bois de chauffage</h4> */}
            <img src={Img2} alt="Slide 2" className="slide-image" />
          </div>
          <div className="full-width-slide">
          {/* <h4 className="text">Ventes de bois de chauffage</h4> */}
            <img src={Img3} alt="Slide 3" className="slide-image" />
          </div>
        </Carousel>
      </div>
    </>
  );
}

export default Slider;
