import React from "react";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { useRef } from 'react';


export const Contact = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_0uunry8",
        "template_joxsm8l",
        

        form.current,
        "YPvlmwL4eddBiAaCY"
        
        
      )
      .then((result) => {
        if (result.status === 200) setSuccess((s) => !s);
      });
      setName("");
    setEmail("");
    setSubject("");
    setMessage("");
    }
    return (
        <>
<div className="callback-form" id="contactus">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="section-heading">
          <h2>Pour toutes <em>demandes</em></h2>
          <span>N'hésitez pas à nous contacter par mail, ou par téléphone au 06-12-89-47-26 / 06-22-51-52-80</span>
        </div>
      </div>
      <div className="col-md-12">
        <div className="contact-form">
          <form ref={form} onSubmit={sendEmail} id="contact" action method="post">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <fieldset>
                  <input name="name" type="text" className="form-control" id="name" placeholder="Nom / Prénom" minlength="2"
                                value={name}
                                onChange={(event) =>
                                  setName(event.target.value)
                                }
                                required />
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <fieldset>
                  <input name="email" type="text" className="form-control" id="email" pattern="[^ @]*@[^ @]*" placeholder="Adresse Mail" value={email}
                                onChange={(event) =>
                                  setEmail(event.target.value)
                                }
                                required />
                </fieldset>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12">
                <fieldset>
                  <input name="subject" type="text" className="form-control" id="subject" placeholder="Sujet" value={subject}
                                onChange={(event) =>
                                  setSubject(event.target.value)
                                }
                                required />
                </fieldset>
              </div>
              <div className="col-lg-12">
                <fieldset>
                  <textarea name="message" rows={6} className="form-control" id="message" placeholder="Votre Message" value={message}
                                onChange={(event) =>
                                  setMessage(event.target.value)
                                }
                                defaultValue={""}
                                required />
                </fieldset>
              </div>
              <div className="col-lg-12">
                <fieldset>
                  <button type="submit" id="form-submit" className="border-button">Envoyer</button>
                  <div className="success">
                              {success && (
                                <p>Votre message a bien été envoyé, Merci !</p>
                              )}
                            </div>
                </fieldset>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

        </>
    )
  }
  export default Contact