import { useRef } from "react";
import Logo from "../assets/images/Logo PNG 2.png"
import "./SideBar.css";

import { FaBars } from "react-icons/fa";

function SideBar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header class="">
      <div class="container">
        <a class="navbar-brand" href="index.html">
      <img src={Logo} alt="logo" />
          {/* <h2>Forest Gab's</h2> */}
        </a>

        <nav ref={navRef}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <a className="nav-link current" href="#top">
                Accueil
                <span className="sr-only" />
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#services">
                Nos Prestations
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contactus">
                Contactez-nous
              </a>
            </li>
          </ul>
          {/* <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button> */}
        </nav>

        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </div>
    </header>
  );
}

export default SideBar;
